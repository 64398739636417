import React, { ReactElement, ReactNode } from "react";
import Head from "next/head";
import Header from "@components/shared/header";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "@redux/shared/actions";
import { RootState } from "@redux/reducers";
import dynamic from "next/dynamic";
import { ModalProvider } from "@components/torts/modalContext";
import PremiumCoverageBlackLogo from "@components/premiumcoverage/logos/premiumCoverageBlackLogo";
import Mobile from "@components/shared/blogPost/sideWidget/mobile";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useDomainContext } from "@hooks/useDomainContext";
import styles from "@components/premiumcoverage/legal/style.module.scss";

const Footer = dynamic(() => import("@components/shared/footer"));
const Modal = dynamic(() => import("@components/shared/modal"));

const CloseIcon = dynamic(() => import("@components/svg/closeIcon"));

import TermsAndConditions from "@components/shared/legal/termsAndConditions";

import PrivacyPolicy from "@components/shared/legal/privacyPolicy";
import CcpaPrivacyNotice from "@components/shared/legal/ccpaPrivacyNotice";
export default function PremiumCoverageWrapper({
    children,
}: {
    children: ReactNode;
}): ReactElement {
    const dispatch = useDispatch();
    const { domain } = useDomainContext();

    const handleClose = () => {
        dispatch(setModal({ active: false, content: undefined }));
    };
    const { active: modalActive, content: modalContent } = useSelector(
        (state: RootState) => state.rootReducer.shared.modal,
    );

    const getModalContent = () => {
        switch (modalContent) {
            case "privacy":
                return (
                    <PrivacyPolicy
                        className={styles["modalStyle"]}
                        mainWrapperClassName={styles["mainContainer"]}
                    />
                );
            case "terms":
                return (
                    <TermsAndConditions
                        className={styles["modalStyle"]}
                        mainWrapperClassName={styles["mainContainer"]}
                    />
                );
            case "ccpaPrivacyNotice":
                return <CcpaPrivacyNotice className={styles["modalStyle"]} />;
            case "sideWidget":
                return <Mobile />;
            default:
                return null;
        }
    };

    return (
        <>
            <Head>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />

                <link
                    href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap"
                    rel="stylesheet"
                />
            </Head>

            <GoogleReCaptchaProvider
                reCaptchaKey={
                    domain?.recaptchaConfig?.key ??
                    (process.env
                        .NEXT_PUBLIC_SEVERAL_BRANDS_GOOGLE_RECAPTCHA_SITE_KEY as string)
                }
                container={{
                    parameters: {
                        badge: "inline", // optional, default undefined
                        theme: "dark", // optional, default undefined
                    },
                }}
            >
                <ModalProvider>
                    <div
                        style={{
                            fontFamily: `'Plus Jakarta Sans', sans-serif`,
                        }}
                    >
                        <Header
                            slogan="Protecting Your Home, One Repair at a Time"
                            backGroundColor="transparent"
                        >
                            <PremiumCoverageBlackLogo />
                        </Header>
                        <main className="max-w-7xl mx-auto block px-4vw lg:px-4">
                            {children}
                        </main>
                        <Footer addCcpaPrivacyNotice />
                        <Modal
                            onClose={handleClose}
                            active={modalActive}
                            closeIcon={<CloseIcon />}
                            backdropClosable
                            modalStyles={{
                                display: "flex",
                                flexDirection: "column",
                                maxHeight:
                                    modalContent === "sideWidget"
                                        ? "80vh"
                                        : "100%",
                            }}
                            modalClassName="h-[90vh] rounded"
                            bodyStyles={{ overflowY: "scroll" }}
                            width={900}
                            header={
                                modalContent === "privacy"
                                    ? ""
                                    : modalContent === "terms"
                                    ? ""
                                    : modalContent === "sideWidget"
                                    ? "Contents"
                                    : undefined
                            }
                        >
                            {getModalContent()}
                        </Modal>
                    </div>
                </ModalProvider>
            </GoogleReCaptchaProvider>
        </>
    );
}
